import mask from 'jquery-mask-plugin';
import summernote from 'summernote';
import 'summernote/dist/summernote.min.css';
import 'summernote/dist/lang/summernote-pt-BR';

function addDeleteForms() {
    $('[data-method]').append(function () {
        if (!$(this).find('form').length > 0) {
            return "\n<form action='" + $(this).attr('href') + "' method='POST' name='delete_item' style='display:none'>\n" +
                "<input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n" +
                "<input type='hidden' name='_token' value='" + $('meta[name="csrf-token"]').attr('content') + "'>\n" +
                '</form>\n';
        } else { return '' }
    })
        .attr('href', '#')
        .attr('style', 'cursor:pointer;')
        .attr('onclick', '$(this).find("form").submit();');
}

$(function () {
    /**
     * Add the data-method="delete" forms to all delete links
     */
    addDeleteForms();

    /**
     * Disable all submit buttons once clicked
     */
    $('form').submit(function () {
        $(this).find('input[type="submit"]').attr('disabled', true);
        $(this).find('button[type="submit"]').attr('disabled', true);
        return true;
    });

    /**
     * Generic confirm form delete using Sweet Alert
     */
    $('body').on('submit', 'form[name=delete_item]', function (e) {
        e.preventDefault();

        const form = this;
        const link = $('a[data-method="delete"]');
        const cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : 'Cancel';
        const confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : 'Yes, delete';
        const title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : 'Are you sure you want to delete this item?';

        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            icon: 'warning'
        }).then((result) => {
            result.value && form.submit();
        });
    }).on('click', 'button.confirm_item', function (e) {
        /**
         * Confirmacao generica para submit de formulario
         */
        e.preventDefault();

        const button = $(this);
        const form = button.parents("form");
        const title = (button.attr('data-trans-title')) ? button.attr('data-trans-title') : 'Você deseja prosseguir?';
        const cancel = (button.attr('data-trans-button-cancel')) ? button.attr('data-trans-button-cancel') : 'Não';
        const confirm = (button.attr('data-trans-button-confirm')) ? button.attr('data-trans-button-confirm') : 'Sim';

        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            icon: 'info'
        }).then((result) => {
            result.value && form.submit();
        });
    }).on('click', 'a[name=confirm_item]', function (e) {
        /**
         * Generic 'are you sure' confirm box
         */
        e.preventDefault();

        const link = $(this);
        const title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : 'Você deseja prosseguir?';
        const cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : 'Não';
        const confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : 'Sim';

        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            icon: 'info'
        }).then((result) => {
            result.value && window.location.assign(link.attr('href'));
        });
    });

    $('[data-toggle="tooltip"]').tooltip();

    // Máscaras
    $('.cpf').mask('000.000.000-00', {reverse: true, clearIfNotMatch: true});
    const maskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    options = {onKeyPress: function(val, e, field, options) {
        field.mask(maskBehavior.apply({}, arguments), options);
    }, clearIfNotMatch: true};
    $('.celular').mask(maskBehavior, options);
    $('.cep').mask('00000-000', { clearIfNotMatch: true });
    $('.titulo_eleitoral').mask('0000 0000 0000', { clearIfNotMatch: true });
    $('.zona_eleitoral').mask('000', { clearIfNotMatch: true });
    $('.secao_eleitoral').mask('0000', { clearIfNotMatch: true });
    $('.data').mask('00/00/0000', { clearIfNotMatch: true });
    $('.horario').mask('00:00:00', { clearIfNotMatch: true });

});
